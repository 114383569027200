<template>
  <b-card no-body>
    <b-card-body>
      <filter-data :filter-action="getDataList" />
    </b-card-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(customer)="data">
        {{ data.item.customer }}
        <div class="text-primary font-small-2">
          {{ data.item.company_name }}
        </div>
      </template>
      <template #cell(username)="data">
        {{ data.item.username }}
      </template>
      <template #cell(drive_date)="data">
        {{ moment(data.item.sdate).format('ll') }} {{ data.item.stime }}
        <div class="text-warning font-small-2">
          {{ moment().diff(moment(data.item.sdate + ' ' + data.item.stime),'hours') }} saat önce
        </div>
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <b-button
            variant="primary"
            size="sm"
            :to="$route.path + '/view/' + data.item.id"
          >Görüntüle</b-button>
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BButton, BCardFooter,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FilterData from '@/views/Webforms/I2saleform/Index/FilterData.vue'

const tableName = 'com_i2saleform'
export default {
  name: 'Index',
  components: {
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    FilterData,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'customer',
          label: 'MÜŞTERİ ADI',
        },
        {
          key: 'username',
          label: 'Satış Danışmanı',
        },
        {
          key: 'drive_date',
          label: 'Tarih / Saat',
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '200px' },
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.sdate AS sdate`,
          `${tableName}.stime AS stime`,
          'com_customer.name AS customer',
          'com_customer.company_name AS company_name',
          'com_user.name AS username',
        ],
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    filters() {
      return this.$store.getters['webformI2saleform/filters']
    },
    dataList() {
      return this.$store.getters['webformI2saleform/dataList']
    },
    dataCounts() {
      return this.$store.getters['webformI2saleform/dataCounts']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 20
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      const where = {}
      if (this.filters.id_com_user) {
        where['com_i2saleform.id_com_user'] = this.filters.id_com_user
      }
      if (this.filters.status === 0) {
        where['com_i2saleform.id_com_interview'] = null
      }
      if (this.filters.status === 1) {
        where['com_i2saleform.id_com_interview !='] = null
      }
      this.dataQuery.where = where
      this.$store.dispatch('webformI2saleform/getDataList', this.dataQuery)
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
